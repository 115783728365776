import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import moment from 'moment'
import { PageLayout } from '../layouts'
import {
  SignUpPageHeader,
  SimpleCallToActionBanner,
  ThreeColumnsList,
  Workflow,
  AitoShowcases,
  QuotesBlock,
} from '../components'
import * as styles from '../styles/pages/integrationTemplate'
import variables from '../styles/variables'

const propTypes = {
  data: PropTypes.shape({
    mdx: PropTypes.object.isRequired
  }).isRequired
}

export default function integrationTemplate({ data }) {
  const { frontmatter, body } = data.mdx
  const {
    banner,
    columns,
    topHeader,
    workflow,
    workflowHeader,
    showcasesHeader,
    showcases,
    quotes,
    columnsHeader,
  } = frontmatter

  let versionDate

  if (frontmatter && frontmatter.version) {
    const mmnt = moment(frontmatter.version)
    versionDate = mmnt.format('[on the] Do [of] MMMM YYYY')
  }

  const firstChild = (
    <SignUpPageHeader
      content={{
        header: topHeader.header,
        signUpInfo: topHeader.info,
        signUpButton: {
          text: topHeader.buttonText,
          info: topHeader.buttonInfo,
          url: topHeader.buttonUrl,
        },
        consoleImage: topHeader.image,
        animation: topHeader.animation
      }}
    />
  )

  return (
    <PageLayout
      navbarProps={{ background: variables.colors.oracle.dark }}
      description={frontmatter.metaDescription}
      firstChild={firstChild}
      sharingTitle={`Aito.ai - ${frontmatter.title}`}
    >
      <Helmet>
        <title>{`Aito.ai - ${frontmatter.title}`}</title>
      </Helmet>
      <styles.Container>
        <MDXRenderer version={versionDate}>{body}</MDXRenderer>
        <styles.PlusPatternContainer>
          <styles.SectionContainer>
            <ThreeColumnsList
              header={columnsHeader}
              items={columns.items}
            />
          </styles.SectionContainer>
        </styles.PlusPatternContainer>

        <styles.SectionContainer>
          <AitoShowcases showcases={showcases} header={showcasesHeader} />
        </styles.SectionContainer>

        {quotes && (
          <styles.QuoteContainer>
            <QuotesBlock data={{
              quotes,
              bubbleColor: 'rgba(0,0,0,0)',
              color: 'white',
            }}
            />
          </styles.QuoteContainer>
        )}

        {workflow && <Workflow
          header={workflowHeader}
          items={workflow}
        />}

        <SimpleCallToActionBanner
          header={banner.header}
          buttonText={banner.buttonText}
          buttonUrl={banner.buttonUrl}
          target={banner.target}
        />

      </styles.Container>
    </PageLayout>
  )
}

integrationTemplate.propTypes = propTypes

export const pageQuery = graphql`
  query IntegrationPath($id: String!) {
    mdx( id: { eq: $id } ) {
      id
      body
      frontmatter {
        path
        title
        info
        metaDescription
        topHeader {
          header
          info
          buttonText
          buttonInfo
          buttonUrl
          animation {
            publicURL
          }
          image {
            childImageSharp {
              fluid(maxWidth: 1100, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        quotes {
          text
          authorName
          authorPosition
          authorImg {
            childImageSharp {
              fluid(maxWidth: 80, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        showcasesHeader
        showcases {
          header
          text
          url
          image
        }
        banner {
          header
          buttonText
          buttonUrl
          target
        }
        columnsHeader
        columns {
          items {
            header
            text
          }
        }
        workflowHeader
        workflow {
          smallHeader
          bigHeader
          text
          imageUrl
        }
      }
    }
  }
`
