import styled from 'styled-components'
import plusPattern from '../../../assets/plus-pattern.svg'
import { TextWrapper } from '../base'
import variables from '../variables'

export const Container = styled.div`
  width: 100%;
`
export const PlusPatternContainer = styled.div`
  width: 100%;
  background-image: url(${plusPattern});
`
export const SectionContainer = styled(TextWrapper)`
  padding: 20px 0 0 0;
  @media ${variables.media.sm} {
    padding: 60px 40px;
  }
  @media ${variables.media.md} {
    padding: 0 30px;
  }
`
export const QuoteContainer = styled(SectionContainer)`
  background: ${variables.colors.pearlBrush.darker};
  @media ${variables.media.md} {
    padding: 60px 30px;
  }
`
